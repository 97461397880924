@use 'src/assets/scss/variables';

.info-page {
    .question {
        padding-top: 10px;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #333;
    }

    .summary{
        font-size: .75rem;
        font-weight: 400;
    }

  .label {
    color: variables.xf-color(white-black, 400);
    font-weight: 400;
    font-size: 0.875rem;
    min-width: 15rem;
  }

  .label-value {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: left;
    color: #333;
    align-self: center;
    position: relative;
  }

  .sub-title {
    padding-top: 0.5rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #333;
  }
}
