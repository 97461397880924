@use 'src/assets/scss/variables';

.custom-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: variables.xf-color(blue, 800) !important;
}

 .custom-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .custom-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .custom-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: variables.xf-color(blue, 800);
}

.custom-theme .mat-radio-button.mat-accent .mat-radio-inner-circle, .custom-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .custom-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .custom-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: variables.xf-color(blue, 800);
}

.custom-theme .mat-radio-button.mat-accent .mat-radio-inner-circle, .custom-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .custom-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .custom-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: variables.xf-color(blue, 800) !important;
}

