@use 'src/assets/scss/variables';

.custom-theme {

  .custom-container .mat-dialog-container {
    padding: 25px;
    background: variables.xf-color(white-black, 500);
    box-shadow: none;
  }

  .mat-button-focus-overlay {
    background: transparent;
  }

}

.custom-theme button.close-btn {
    float: right;
    top: -12px;
    right: -12px;
    color: white;
    font-size: 24px !important;
    font-weight: 400 !important;
    background: transparent !important;
    border: none;
    position: relative;
}
.form-question {
    padding-top: 25px;
    font-weight: 500;
    font-size: 15px; // 0.875rem;
    line-height: 1.25rem;
    color: white;
    letter-spacing: .5px;
    width: 100%;
  }

.form-title {
    font-size: 22px;
    font-weight: 700;
    color: white;
    line-height: 0;
    letter-spacing: .02rem;
  }

.add-spacer {
    margin: 25px;
  }

.add-medium-spacer {
    margin: 15px;
  }

.add-small-spacer {
    margin: 5px;
  }

.form-id {
  margin-bottom: 0.313rem;
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 500;
  color: variables.xf-color(white-black, 200);
  letter-spacing: normal;
}

  .item-input {
    height: 38px;
    width: -webkit-fill-available;
  }

  .left-item {
    padding-right: 10px;
  }

  .group-label {
    margin-top: .313rem;
    font-size: 0.85rem;
    line-height: 1rem;
    font-weight: 500;
    color: variables.xf-color(white-black, 200);
    letter-spacing: normal;
  }