
@use 'src/assets/scss/variables';


@media(min-width:1400px) {
  .mat-tab-group {
     width: auto;
  }
}

@media(max-width:1280px){
  .mat-tab-group  {
    width: auto !important;
  }
}

@media(max-width:1400px){
  .mat-tab-group  {
    width: auto;
  }
}

.mat-tab-label {
  min-width: auto;
  .mat-tab-label-content {
    color: variables.xf-color(white-black, 900);
    font-size: 0.875rem;
    min-width: auto;
    font-weight: 500;
  }

  .mat-tab-link {
    min-width: auto;
  }
}
