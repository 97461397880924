@use 'src/assets/scss/variables';

button {
  font-family: 'Manrope';
  flex-flow: row nowrap;
  display: flex;
  cursor: pointer;

  // (black button)
  &.primary {
    width: 65px;
    height: 30px;
    border-style: none;
    color: variables.xf-color(white-black, 50);
    background-color: variables.xf-color(white-black, 500);
    font-size: 14px;
    line-height: 20px;
    align-items: center;
  }

  // (black button disabled)
  &.disabled {
    width: 65px;
    height: 30px;
    border-style: none;
    color: variables.xf-color(white-black, 50);
    background-color: variables.xf-color(white-black, 300);
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    cursor: pointer;
  }

  // (any button disabled)
  &:disabled {
    cursor: default;
  }

  // (black priority button)
  &.priority {
    height: 30px;
    border-style: none;
    color: variables.xf-color(white-black, 50);
    background-color: variables.xf-color(white-black, 500);
    align-items: center;
    cursor: pointer;
    .icon {
      padding-right: 0px;
    }
  }

  &.download {
    width: 100px;
    height: 30px;
    border-style: none;
    color: variables.xf-color(white-black, 50);
    background-color: variables.xf-color(white-black, 500);
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    cursor: pointer;
  }

  &.edit-submit {
    width: 86px;
    height: 36px;
    min-width: 60px;
    justify-content: center;
    align-items: center;
    background-color: variables.xf-color(blue, 100);
    border-radius: 6px;
    border-color: variables.xf-color(blue, 100);
    float: right;
  }


  &.edit-cancel {
    width: 86px;
    height: 36px;
    min-width: 60px;
    margin-left: 0px;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: variables.xf-color(white-black, 50);
    border-radius: 6px;
    background-color: variables.xf-color(white-black, 500);
    color: variables.xf-color(white-black, 50);
    float: right;
    //margin-right: 10px
  }

  &.edit-add {
    width: 3.125rem;
    height: 2.75rem;
    min-width: 60px;
    justify-content: center;
    align-items: center;
    color: variables.xf-color(white-black, 50);
    background-color: variables.xf-color(white-black, 500);
    border-color: variables.xf-color(white-black, 50);
    float: right;
  }

  &.profile-menu {
    width: 42px;
    height: 42px;
    border-style: none;
    align-items: center;
    cursor: pointer;
    .icon{
      color: grey;
    }
  }

  &.side-nav-edit {
    margin-right: 20px !important;
    margin-top: 5px !important;
  }

  &.menu-icon {
    background-color: variables.xf-color(white-black, 50);
    border-style: none;
    .icon {
      color: variables.xf-color(white-black, 500);
    }
  }

  &.search-btn {
    min-width: 82px;
    height: 42px;
    border-style: none;
    color: variables.xf-color(white-black, 50);
    background-color: variables.xf-color(white-black, 500);
    font-size: .875rem;
  }

  .icon {
    font-size: 20px;
    padding-right: 2px;
  }
}

.edits-btn-position {
  place-content: center flex-end;
  flex-direction: row;
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
}
