.xps-swagger-ui .swagger-ui {
    line-height: normal;
}

.xps-swagger-ui .swagger-ui .title {
    padding: 0px;
    line-height: normal;
    max-width: unset;
}

.xps-swagger-ui .swagger-ui .info {
    margin-top: 0px;
    margin-bottom:10px;
}

.xps-swagger-ui .swagger-ui .scheme-container {
    padding-top: 10px;
}

.xps-swagger-ui .swagger-ui .opblock-tag {
    line-height: normal;
    font-size: 24px;
}

.xps-swagger-ui .swagger-ui .markdown h2 {
    line-height: normal;
    font-size: 24px;
    font-weight: bold;
}

.xps-swagger-ui .swagger-ui .markdown h3 {
    line-height: normal;
    font-size: 16px;
    font-weight: bold;
}
.apikey-dropdown .dropdown-container {
    padding-top: 0px !important;
}