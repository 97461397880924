// apply custom element styles
@use '@angular/material' as mat;
@use 'assets/scss/buttons';
@use 'assets/scss/slider';
@use 'assets/scss/edit-layout';
@use 'assets/scss/tables';
@use 'assets/scss/tabs';
@use 'assets/scss/check-boxes';
@use 'assets/scss/confirmation';
@use 'src/assets/scss/variables';
@use 'assets/scss/info-page';
@use 'assets/scss/data-display';
@use 'assets/scss/page-side-nav';
@use 'assets/scss/swagger-ui';
@use 'assets/scss/radio-group';
@use 'assets/scss/mat-list';
@use 'assets/scss/date-picker';
@use 'assets/scss/bottom-sheet';
@use 'assets/scss/phone-box';

@use '@xform/xformative-angular/xps-functions';

@include mat.core();

@import './custom-theme.scss';

// apply custom colors
.custom-theme {
  $app-xf-colors: (
    background-light: #FAFAFA,
    background-dark: #E8E8E8,
  );
  $custom-theme: map-merge($custom-theme, (app-xf-colors: $app-xf-colors));

  // use our theme with angular-material-theme mixin
  @include mat.all-component-themes($custom-theme);
  @include mat.all-component-typographies($typography);

  .mat-typography {

    h5 {
      font-size: 1.5rem;
      line-height: 1.758rem;
      font-style: normal;
    }

    .mat-body-2 {
      opacity: 60%;
    }

    p {
      margin: 0 0 2px;
    }
  }
}

* {
  font-family: 'Manrope';
}

*:focus {
  outline: none !important;
}

html, body {
  height: 100%;

  @media only screen and (min-width: 480px) {
    min-height: 800px
  }
}

body {
  margin: 0;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 1.2em;
}

mat-form-field {
  &.pb-0 {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}

.custom-container .mat-dialog-container {
  border-radius: 0;
  padding: 25px;
}

.custom-profile-menu-container .mat-dialog-container {
  border-radius: 0 !important;
  padding: 10px !important;
}

.backdrop-background {
  opacity: 0;
}

// For header menu
.mat-menu-panel.my-menu {
  border-radius: 0;
  background-color: variables.xf-color(white-black, 500);
  width: 13.75rem;
  overflow: hidden;
  padding: 20px 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

