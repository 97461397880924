@use 'src/assets/scss/variables';

.data-display-small {
    border-radius: 1rem;
    border: solid;
    border-width: .1rem;
    border-color: variables.xf-color(white-black, 500);
    color: variables.xf-color(white-black, 600);
    padding-left: .3rem;
    padding-right: .3rem;
    background-color: variables.xf-color(blue, 300);
    margin: .15rem;
    font-size: .875rem;
}

.data-list {
    display: flex;
}

.data-display-large {
    position: relative;
    border-radius: 1rem;
    border: solid;
    border-width: .1rem;
    border-color: variables.xf-color(white-black, 500);
    color: variables.xf-color(white-black, 600);
    margin: .4rem;
    background-color: variables.xf-color(purple, 400);
    font-size: .875rem;
    min-width: 24rem;
    max-width: 24rem;
    padding-bottom: 0.625rem;
}

.filter-box {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    min-width: 7.5rem !important;
}

.data-display-medium {
    position: relative;
    border-radius: 1rem;
    border: solid;
    border-width: .1rem;
    border-color: variables.xf-color(white-black, 500);
    color: variables.xf-color(white-black, 600);
    margin: .4rem;
    background-color: variables.xf-color(yellow, 400);
    font-size: .875rem;
    min-width: 24rem;
    max-width: 24rem;
    padding-bottom: 0.625rem;
}

.title {
    padding: 0.625rem;
    padding-bottom: 0;
    font-weight: bold;
    min-width: 18.5rem;
    max-width: 18.5rem;
}

.dat-display-button {
    position: absolute;
    top: .05rem;
    right: 1.8rem;
    max-width: .25rem;
    margin-left: auto;
    padding-top: .25rem;
}

.medium-menu-icon {
    border-radius: .75rem;
    max-width: .25rem;
    border-style: none;
    .icon {
      color: variables.xf-color(white-black, 500);
      font-size: 24px;
    }
}

.yellow {
    background-color: variables.xf-color(yellow, 400);
}

.purple {
    background-color: variables.xf-color(purple, 400);
}

.with-padding {
    padding-top: .30rem;
}

.data-label {
    color: variables.xf-color(white-black, 400);
    font-weight: 400;
    font-size: 0.875rem;
    max-width: 12rem;
    padding-left: 0.625rem;
}

.data-value {
  margin-top: 0.188rem;
    max-width: fit-content;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: left;
    color: #333;
    align-self: center;
    position: relative;
    padding: 0;
}

.mat-menu-panel.card-more-options {
    min-width: 5rem !important;
    border-radius: 0 !important;
    background-color: variables.xf-color(white-black, 500) !important;
    position:absolute;
    top: 0rem;
    left: -2.8rem;
}

.summary-text-purple {
    color: variables.xf-color(purple, 500);
    font-size: 0.80rem;
}
