@use 'src/assets/scss/variables';

.mat-table {
    border-collapse: collapse;
    margin: 10px 0;
    min-width: 100%;

    &.clickable > .mat-row:hover {
        cursor: pointer;
    }

    .mat-header-cell {
        background-color: variables.xf-color(blue, 200);
        color: variables.xf-color(white-black, 500) !important;
        font-size: .875rem !important;
        text-align: left;
        line-height: 1.25rem;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        font-style: bold;
        width: 20rem;
    }

    .mat-cell {
        background-color: variables.xf-color(white-black, 50);
        font-size: .75rem !important;
        text-align: left;
        line-height: 1.25rem;
        vertical-align: top;
        min-height: 100%;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        padding-left: 0.1325rem;
        word-break:break-word;
    }
}

.more-options {
    border-radius: 0 !important;
    background-color: variables.xf-color(white-black, 500) !important;
    overflow: hidden;
}

.button-holder {
  padding-right: 0.625rem !important;
  width: 8% !important;
}

.button-holder-lg {
    padding-right: 0.625rem !important;
    width: 14% !important;
}

.col-perc-5 {
    min-width: 5% !important;
}

.col-perc-10 {
    min-width: 10% !important;
}

.mat-menu-panel {
    min-height: 0px !important;
}
