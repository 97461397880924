@use 'src/assets/scss/variables';

.notification-checkbox {
    color: white;
    margin: 0;
    font-size: 14px;
    letter-spacing: 0;
}

.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: variables.xf-color(blue, 100) !important;
}

.custom-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .custom-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: variables.xf-color(blue, 800) !important;
}
