@use 'src/assets/scss/variables';

.custom-theme {
  .default-slide-toggle {
    &.mat-slide-toggle.mat-checked {
      .mat-slide-toggle-bar {
        background-color: variables.xf-color(blue, 800);
      }
      .mat-slide-toggle-thumb {
        background-color: white;
      }
      .mat-ripple-element {
        background-color: variables.xf-color(blue, 800);
      }
    }
    // unchecked
    .mat-slide-toggle-bar {
      background-color: grey;
    }
    .mat-slide-toggle-content {
      color: white;
      letter-spacing: 0px;
    }
  }
}