@use 'src/assets/scss/variables';

.confirmation-info{
    padding-top: 1.563rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: white;
    letter-spacing: .031rem;
    width: 100%;
  }

.confirmation-title {
    font-size: 1.375rem;
    font-weight: 700;
    color: white;
    line-height: 0;
    letter-spacing: .02rem;
  }

.width-spacer {
  width: 25% !important;
}

.add-spacer {
    margin: 25px;
  }

.add-medium-spacer {
    margin: 1rem
  }

.add-small-spacer {
    margin: .313rem
}

.add-small-top-padding {
  padding-top: 0.625rem;
}

.add-med-top-padding {
  padding-top: 1.25rem;
}
