ngx-intl-tel-input {
    height: 44px !important;
    min-height: 35px !important;
    min-width: 100% !important;
    width: 100% !important;
    border-style: none;
    font-size: 18px;

    > .iti.iti--allow-dropdown {
        display: block !important;
        padding: none !important;
        min-width: 100% !important;
        width: 100% !important;
        height: 44px !important;
        min-height: 35px !important;
            > input {
                height: 44px !important;
                min-height: 35px !important;
                min-width: 100% !important;
                width: 100% !important;
            }
    }
}


.intl-tel-input { width: 100% !important; }
